import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ArticleListPage } from '~/components/ArticleListPage/ArticleListPage';
import { ContentfulPageSection, Article } from '~/types/types';

const Blog: FC<BlogProps> = ({ data }) => {
  const articles = data.articles.edges.map(({ node }) => node);
  return (
    <Layout>
      <Metadata title={data.page?.name} />
      <ArticleListPage
        hero={data.page.hero}
        articles={articles}
        sections={data.page.sections}
      />
    </Layout>
  );
};

interface BlogProps {
  data: BlogPageData;
}

export interface BlogPageData {
  articles: {
    edges: {
      node: Article;
    }[];
  };
  page: {
    name: string;
    hero: ContentfulPageSection;
    sections: ContentfulPageSection[];
  };
}

export const query = graphql`
  query BlogPage($locale: String) {
    articles: allContentfulArticle(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
    }
    page: contentfulPage(slug: { eq: "blog" }, node_locale: { eq: $locale }) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;

export default Blog;
